<template>
  <div class="modal is-active">
    <div class="modal-background pointer"></div>
    <div class="modal-card has-rounded-corners">
      <section class="modal-card-body p-4">
        <div class="p-4" v-if="allowsLocation">
          <div class="container has-text-centered has-text-grey has-text-weight-semibold is-size-5 mb-4">
            Great! Your device location settings has been configured.
          </div>
          <div class="container has-text-centered">
            <button @click="$router.push({ name: 'Stage' })" class="button is-primary">
              {{ t('Close') }}
            </button>
          </div>
        </div>
        <div v-if="!allowsLocation">
          <div class="container has-text-grey has-text-weight-semibold is-size-5 mb-0 mt-1">
            This activity requires your device's location.
          </div>
          <div class="container has-text-grey is-size-7 mb-3">
            Note: We do not track your location history, and only use the location for a one-off check when completing a game task. Your latest location will be shared to a secured dashboard with the facilitators when you complete tasks, and this will cease as soon as the tab is closed.
          </div>
          <div class="tabs">
            <ul>
              <li @click="tutType = 'iPhone'" :class="{ 'is-active': tutType === 'iPhone' }"><a>iPhone</a></li>
              <li @click="tutType = 'Android'" :class="{ 'is-active': tutType === 'Android' }"><a>Android</a></li>
            </ul>
          </div>
          <div v-if="tutType === 'iPhone'" class="container px-4">
            <div class="is-size-6">Step 1: Open "Settings".</div>
            <img
            class="fit"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-37-41.jpg?alt=media&token=3ed35042-136c-40e6-a719-53de2b0d8a7e" />
            <div class="is-size-6">Step 2: Scroll and open "Privacy".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-37-44%20(2).jpg?alt=media&token=873292f1-5576-44ad-97bf-6e3bb03de04c" />
            <div class="is-size-6">Step 3: Turn "Location Services" to "On".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-37-45.jpg?alt=media&token=7b9b6da5-d2d0-4537-9af2-5f353f789b51" />
            <div class="is-size-6">Step 4: In "Location Services", scroll down and click on "Safari Websites", and enable "While Using the App".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2FScreenshot%202024-01-23%20at%203.03.37%E2%80%AFPM.jpeg?alt=media&token=e9b36d4d-7d7e-437b-9421-a81957804519" />
            <div class="is-size-6">Step 5: Go back to "Settings", scroll and open "Safari".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-37-43.jpg?alt=media&token=eaf14a7c-96d5-40ef-a17c-37def8243c69" />
            <div class="is-size-6">Step 6: Scroll and open "Location".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-37-42.jpg?alt=media&token=18439604-3793-4bbc-9bb3-735da1bb972c" />
            <div class="is-size-6">Step 7: Set to "Ask".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-37-44.jpg?alt=media&token=1db3f98d-5673-4040-b674-5bd703ccf7cc" />
            <div class="is-size-6">Step 8: If you see this, set to "Allow" instead of "Deny".</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2Fphoto_2022-04-12_21-54-21.jpg?alt=media&token=53b3f34e-57dc-4ce6-8714-25a29ea69395" />
            <div class="is-size-6">Step 9: Go back to homepage and open the Safari App and click on this "Aa" icon.</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2FSafari%20Step%201.jpeg?alt=media&token=c625c308-492a-4b09-8ab3-ffdde04ba59a" />
            <div class="is-size-6">Step 10: Make sure that "Location" is set to "Allow"</div>
            <img
            class="fit mb-2"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2FSafari%20Step%202.jpeg?alt=media&token=1edd1928-1e4a-4715-b9ad-5b77c62b2b94" />
            <div class="is-size-6">Step 11: Refresh this page.</div>
            <img
            class="fit"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2FScreenshot_128.jpg?alt=media&token=04b9551f-69a3-4cd9-bfb6-3805908048bf" />
          </div>
          <div v-if="tutType === 'Android'" class="container px-4">
            <div class="is-size-6 has-text-weight-semibold mb-3">Your screens may not look exactly as shown as different Android phones have different themes.</div>
            <div class="is-size-6">Step 1: Open "Settings" and click on "Location".</div>
            <img
            class="fit"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2FScreenshot_129.jpg?alt=media&token=fde401e2-ca72-4921-862a-657f48442d11" />
            <div class="is-size-6">Step 2: Enable "Use location".</div>
            <img
            class="fit"
            src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FLocation%20tutorial%2FScreenshot_130.jpg?alt=media&token=289744a8-69a5-40f5-9efc-40be159614bd" />
            <div class="is-size-6">Step 3: Refresh this page.</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationTutorial',
  data() {
    return {
      tutType: 'iPhone',
      allowsLocation: false
    }
  },
  mounted () {
    this.checkLocationPermission()
  },
  methods: {
    checkLocationPermission(){
      navigator.geolocation.getCurrentPosition(pos => {
        this.allowsLocation = true
        this.$store.commit('getUserLocationMutation', {
          userAllowsLocationDetection: true,
          lat: pos.coords.latitude,
          lon: pos.coords.longitude
        })
      }, err => {
        this.allowsLocation = false
        // if (
        //   this.$store.state.userLocation &&
        //   !this.$store.state.userAllowsLocationDetection
        // ) {
        //   this.allowsLocation = true
        // } else {
        //   this.allowsLocation = false
        // }
      })
    }
  }
}
</script>

<style>
.select-location-map{
  width: 100%;
  height: 450px;
}
</style>
